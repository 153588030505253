// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bY_k5";
export var caseStudyBackground__lineColor = "bY_kX";
export var caseStudyHead__imageWrapper = "bY_nG";
export var caseStudyProjectsSection = "bY_k6";
export var caseStudyQuote__bgRing = "bY_k2";
export var caseStudyQuote__bottomVideo = "bY_lK";
export var caseStudyQuote__light = "bY_l9";
export var caseStudyQuote__lightLast = "bY_nH";
export var caseStudyVideo__ring = "bY_k8";
export var caseStudy__bgDark = "bY_kT";
export var caseStudy__bgLight = "bY_kS";
export var caseStudy__bgLightReverse = "bY_lT";